/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect } from 'react';
import {
  Input,
  Button,
  Form,
  Row,
  Col,
  Select,
  Checkbox,
  InputNumber,
  DatePicker,
  Divider
} from 'antd';
import moment from 'moment';
import countries from '../../lib/countries';

const formInit = {
  // sector: 'Pharmaceuticals',
};

const ClientForm = ({ isNew, data, onSubmit, clients }) => {
  const [form] = Form.useForm();
  const initialValues = { ...formInit, ...data };
  useEffect(() => form.resetFields());
  if (initialValues?.licencingPeriodStart)
    initialValues.licencingPeriodStart = moment(data?.licencingPeriodStart);
  if (initialValues?.licencingPeriodEnd)
    initialValues.licencingPeriodEnd = moment(data?.licencingPeriodEnd);
  const onFinish = async (values, isNew2) => {
    values.licencingPeriodEnd =
      values.licenceType === 'Monthly'
        ? moment(values.licencingPeriodStart).add(1, 'month')
        : moment(values.licencingPeriodStart).add(1, 'year');
    values.companyPrefix = values.companyPrefix.toUpperCase();
    onSubmit(values, isNew2);
  };

  return (
    <div>
      <Form
        className="form-horizontal"
        initialValues={initialValues}
        onFinish={(values) => onFinish(values, !data)}
        layout="horizontal"
        form={form}
      >
        <div style={{ textAlign: 'center', marginBottom: '30px' }}>
          <h2 className="header-component__page-heading">
            {isNew ? 'Add New Client' : 'Edit Client'}
          </h2>
        </div>

        <Divider style={{ border: '2px' }}>Company Info</Divider>

        <Row type="flex" gutter={16}>
          <Col span={12}>
            <Form.Item
              name="companyName"
              rules={[{ required: true, message: 'Please provide company name!' }]}
            >
              <Input placeholder="Company Name" />
            </Form.Item>

            <Form.Item name="status">
              <Select placeholder="Status">
                <Select.Option value="ACTIVE">ACTIVE</Select.Option>
                <Select.Option value="INACTIVE">INACTIVE</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="country"
              rules={[{ required: true, message: 'Please select country!' }]}
            >
              <Select placeholder="Select country" allowClear showSearch>
                {countries && countries.map((c) => (
                  <Select.Option key={c.code} value={c.name}>
                    {c.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="city" rules={[{ required: true, message: 'Please provide city!' }]}>
              <Input placeholder="City" />
            </Form.Item>

            <Form.Item name="zip" rules={[{ required: true, message: 'Please provide zip!' }]}>
              <Input placeholder="Zip" />
            </Form.Item>

            <Form.Item name="taxId">
              <Input placeholder="Tax ID" />
            </Form.Item>

            <Form.Item name="parentOfClient">
              <Select placeholder="Parent Of" mode="multiple" allowClear>
                {clients?.data?.items?.map(
                  (client) =>
                    client._id !== data?._id && (
                      <Select.Option key={client._id} value={client._id}>
                        {client.companyName}
                      </Select.Option>
                    )
                )}
              </Select>
            </Form.Item>
            <Form.Item name="childOfClient">
              <Select placeholder="Child Of" allowClear>
                {clients.data?.items?.map(
                  (client) =>
                    client._id !== data?._id && (
                      <Select.Option key={client._id} value={client._id}>
                        {client.companyName}
                      </Select.Option>
                    )
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="companyPrefix"
              rules={[
                {
                  required: true,
                  message: 'Please set company prefix. Length 3 characters!',
                  min: 3,
                  max: 3
                }
              ]}
            >
              <Input placeholder="Company Prefix" />
            </Form.Item>
            <Form.Item
              name="phone"
              rules={[
                { required: false, message: 'Please enter phone number!' },
                {
                  validator: (_, value) => {
                    if (value) {
                      const reg = /^[0-9+]{8,}$/g;
                      if (!reg.test(value))
                        return Promise.reject(new Error('Please enter valid phone number!'));
                    }
                    return Promise.resolve();
                  }
                }
              ]}
              normalize={(value) => value.trim()}
            >
              <Input placeholder="Phone" />
            </Form.Item>
            <Form.Item name="state" rules={[{ required: true, message: 'Please provide state' }]}>
              <Input placeholder="State" />
            </Form.Item>

            <Form.Item
              name="address"
              rules={[{ required: true, message: 'Please provide address!' }]}
            >
              <Input placeholder="Address" />
            </Form.Item>

            <Form.Item name="sector" rules={[{ required: true, message: 'Please select sector' }]}>
              <Select placeholder="Sector">
                <Select.Option value="Pharmaceuticals">Pharmaceuticals</Select.Option>
                <Select.Option value="Biotechnology">Biotechnology</Select.Option>
                <Select.Option value="Medical Devices">Medical Devices</Select.Option>
                <Select.Option value="Diagnostics">Diagnostics</Select.Option>
                <Select.Option value="Consumer Health">Consumer Health</Select.Option>
                <Select.Option value="Animal Health">Animal Health</Select.Option>
                <Select.Option value="Agriculture">Agriculture</Select.Option>
                <Select.Option value="Chemicals">Chemicals</Select.Option>
                <Select.Option value="Other">Other</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="website">
              <Input placeholder="Website" />
            </Form.Item>

            <Form.Item name="affiliateOfClient">
              <Select placeholder="Affiliate Of" mode="multiple" allowClear>
                {clients?.data?.items?.map(
                  (client) =>
                    client._id !== data?._id && (
                      <Select.Option key={client._id} value={client._id}>
                        {client.companyName}
                      </Select.Option>
                    )
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{ border: '2px' }}>Licences</Divider>

        <Row type="flex" gutter={16}>
          <Col span={12}>
            <Form.Item name="totalCreatorLicences">
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Creator Licenses"
                min={initialValues?.totalCreatorLicences || 0}
              />
            </Form.Item>
          </Col>

          {!isNew && (
            <Col span={12}>
              <Form.Item label="Remaining Licenses">
                <InputNumber
                  min={0}
                  value={
                    initialValues?.licencedCreators
                      ? initialValues?.totalCreatorLicences -
                      initialValues?.licencedCreators?.length
                      : 0
                  }
                  disabled
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row type="flex" gutter={16}>
          <Col span={12}>
            <Form.Item name="totalPublisherLicences">
              <InputNumber
                style={{ width: '100%' }}
                placeholder="Publisher Licenses"
                min={initialValues?.totalPublisherLicences || 0}
              />
            </Form.Item>
          </Col>
          {!isNew && (
            <Col span={12}>
              <Form.Item label="Remaining Licenses">
                <InputNumber
                  min={0}
                  value={
                    initialValues?.licencedPublishers
                      ? initialValues?.totalPublisherLicences -
                      initialValues?.licencedPublishers?.length
                      : 0
                  }
                  disabled
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row type="flex" gutter={16}>
          <Col span={12}>
            <Form.Item
              name="licencingPeriodStart"
              rules={[{ required: true, message: 'Please select licencing period start day' }]}
            >
              <DatePicker
                placeholder="Licence Period Start"
                style={{ width: '100%', height: 36 }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="licenceType"
              rules={[{ required: true, message: 'Please select licence type' }]}
            >
              <Select style={{ width: '100%' }} placeholder="License Type">
                <Select.Option value="Monthly">Monthly</Select.Option>
                <Select.Option value="Annual">Annual</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider style={{ border: '2px' }}>Additional Features</Divider>

        <Row>
          <Col span={8}>
            <Form.Item name="veeva" valuePropName="checked">
              <Checkbox>Veeva</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="exeevo" valuePropName="checked">
              <Checkbox>Exeevo</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="crm" valuePropName="checked">
              <Checkbox>CRM</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="ai" valuePropName="checked">
              <Checkbox>AI</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="importExport" valuePropName="checked">
              <Checkbox>Import/Export</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="isAgency" valuePropName="checked">
              <Checkbox>Is Agency</Checkbox>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="template" valuePropName="checked">
              <Checkbox>Templates</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row justify="center">
          <Col span={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ width: '100%', marginTop: '20px' }}>
                {isNew ? 'Add' : 'Edit'} Client
              </Button>
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </div>
  );
};

export default ClientForm;
