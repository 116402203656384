import React, { useState } from 'react';
import { Modal, Select, Button, Typography, Tag } from 'antd';

const { Text } = Typography;

const DeleteAgentModal = ({ agentContent, creatorOptions, onConfirm, onCancel }) => {
  const [transferSelections, setTransferSelections] = useState([]);

  // Determine brands without creators
  const brandsWithoutCreators = agentContent.brands.filter((brand) => {
    const creatorsForBrand = creatorOptions.filter((creator) =>
      creator.brand.includes(brand._id)
    );
    return creatorsForBrand.length === 0;
  });

  console.log('Agent Content:', agentContent);
  console.log('Creator Options:', creatorOptions);

  const handleSelectionChange = (brand, value) => {
    const transfer = { brand, user: value, agent: agentContent?.agent };
    // Add to transferSelections if not already present
    if (!transferSelections.some((t) => t.brand === brand)) {
      setTransferSelections([...transferSelections, transfer]);
    } else {
      // Update the existing transfer
      setTransferSelections(
        transferSelections.map((t) => (t.brand === brand ? transfer : t))
      );
    }
  };

  const handleConfirm = () => onConfirm(transferSelections);

  // Determine if the confirm button should be disabled
  const isConfirmDisabled =
    transferSelections.length !== agentContent.brands.length || brandsWithoutCreators.length > 0;

  // Set the note text based on brands without creators
  const noteText =
    brandsWithoutCreators.length > 0 ? (
      <>
        Note: this User has associated data in Qoolo. Before deleting this User, you must select a
        Creator for each Brand to transfer ownership of this data.
        <br />
        <br />
        <Text type="danger">
          However, at least one Brand does not have any Creators. Please assign at least one Creator
          to each Brand before returning to delete this User.
        </Text>
        <br />
        <br />
        For custom needs, please reach out to support@qoolo.com.
      </>
    ) : (
      <>
        Note: this user has associated data in Qoolo. Before deleting this user, please select a
        Creator for each Brand to transfer ownership of this data. For custom needs, please reach
        out to support@qoolo.com.
      </>
    );

  return (
    <Modal
      title={<h3 style={{ fontSize: '1.7em', fontWeight: 600, textAlign: 'center' }}>Delete Agent Support</h3>}
      open={true}
      onCancel={onCancel}
      footer={[
        <Button
          key="submit"
          type="primary"
          style={{ width: '97%' }}
          onClick={handleConfirm}
          disabled={isConfirmDisabled} // Disable when necessary
        >
          Transfer ownership and Delete Agency User
        </Button>,
      ]}
    >
      <div>
        <p>
          <Text strong>Agency:</Text> {agentContent.agencyName}
        </p>
        <p>
          <Text strong>User:</Text> {agentContent.agentName}
        </p>
        <p>
          <Text strong>Branch:</Text> {agentContent.branchName}
        </p>
        <p>
          <Text strong>Brands:</Text>{' '}
          {agentContent.brands.map((brand, index) => (
            <Tag key={index} color="orange">
              {brand.name}
            </Tag>
          ))}
        </p>

        <Text type="danger" style={{ display: 'block', marginBottom: '10px' }}>
          {noteText}
        </Text>

        {agentContent.brands.map((brand, index) => {
          const creatorsForBrand = creatorOptions.filter((creator) =>
            creator.brand.includes(brand._id)
          );
          return (
            <div key={index} style={{ marginBottom: '10px' }}>
              <Text strong>{brand.name}</Text>
              <Select
                style={{ width: '100%', marginTop: '5px' }}
                placeholder={
                  creatorsForBrand.length > 0
                    ? 'Select Creator for Data Transfer'
                    : 'No Creators Available'
                }
                onChange={(value) => handleSelectionChange(brand._id, value)}
                disabled={creatorsForBrand.length === 0}
              >
                {creatorsForBrand.map((creator) => (
                  <Select.Option key={creator._id} value={creator._id}>
                    {creator.firstName} {creator.lastName} ({creator.email})
                  </Select.Option>
                ))}
              </Select>
              {creatorsForBrand.length === 0 && (
                <Text type="danger">No Creators available for this Brand.</Text>
              )}
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default DeleteAgentModal;
