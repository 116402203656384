/* eslint-disable no-unsafe-optional-chaining */
import React, { useContext, useEffect, useState } from 'react';
import {
  notification, Layout, Button, Modal, Popconfirm, Tag, Table, Empty, Input
} from 'antd';
import Axios from 'axios';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';
import { ReactComponent as Magnifying } from '../../icons/magnifying-glas.svg';
import HeaderComponent from '../../components/base/HeaderComponent';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import AgentsForm from '../../components/forms/AgentsForm';
import RequestQooloSupportForm from '../../components/forms/RequestQooloSupportForm ';
import DeleteAgentModal from '../../components/modals/DeleteAgentModal';

const Agents = () => {
  const currentuser = useContext(UserContext);
  const [branchData, setBranchData] = useState();
  const [brandsData, setBrandsData] = useState();
  const [agentModal, setAgentModal] = useState(false);
  const [agentsOptions, setAgentsOptions] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [currentAgent, setCurrentAgent] = useState(null);

  // For edit end delete
  const [selectedAgent, setSelectedAgent] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [agentContent, setAgentContent] = useState([]);
  const [creatorOptions, setCreatorOptions] = useState([]);
  const [showSupportModal, setShowSupportModal] = useState(false);

  // State to track search input
  const [searchTerm, setSearchTerm] = useState('');

  const getClient = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/clients/${currentuser?.data?.client?._id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      response?.data?.agents ? setSelectedAgents(response.data.agents) : setSelectedAgents([]);
    } catch (error) {
      console.log(error);
    }
  };

  const getBranches = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/client-branches`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      response?.data?.items ? setBranchData(response.data.items) : setBranchData([]);
    } catch (error) {
      console.log(error);
    }
  };

  const getBrands = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/brands`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      response?.data?.items ? setBrandsData(response.data.items) : setBrandsData([]);
    } catch (error) {
      console.log(error);
    }
  };

  const getAgentsOptions = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/client-agents-options`,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        });

      response?.data ? setAgentsOptions(response.data) : setAgentsOptions([]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddAgent = async (values) => {
    try {
      await Axios.post(`${SERVER_URL}/client-agent`, values,
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        });
      notification.success({
        message: 'Agent added.',
        placement: 'topLeft',
        duration: 2
      });
      setAgentModal(false);
      getClient();
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with adding agent. Please try later.',
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const handleUpdateAgent = async (values) => {
    try {
      await Axios.put(`${SERVER_URL}/client-agent`, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Agent updated.',
        placement: 'topLeft',
        duration: 2
      });
      setAgentModal(false);
      getClient();
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with updating agent. Please try later.',
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  useEffect(() => {
    getClient();
    getBranches();
    getBrands();
    getAgentsOptions();
  }, []);

  const addAgencySupport = () => {
    setAgentModal(true);
    setCurrentAgent(null); // Reset for new agent creation
  };

  const editAgent = (agent) => {
    setAgentModal(true);
    setCurrentAgent(agent); // Set the current agent for editing
  };

  const handleSupportRequest = async (values) => {
    try {
      const supportRequestResponse = await Axios.post(`${SERVER_URL}/client-support-request`, values, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });

      if (supportRequestResponse?.status === 204) {
        setShowSupportModal(false); // Close modal after submission
        notification.success({
          message: 'Support request submitted successfully.',
          placement: 'topLeft',
          duration: 2
        });
      }
    }
    catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with support request. Please try later.',
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const transferDataAndDeleteAgent = async (transferSelections) => {
    try {
      const transferOwnershipResponse = await Axios.post(`${SERVER_URL}/client-agent-transfer-content`, transferSelections, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });

      // Now we should attempt to delete the agent again
      if (transferOwnershipResponse?.status === 200) {
        await Axios.delete(`${SERVER_URL}/client-agent/${selectedAgent.agent}`, {
          withCredentials: true,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        });
        setSelectedAgent(null);
        setShowDeleteModal(false);
        getClient();
        notification.success({
          message: 'Content transfered and Agent removed successfully.',
          placement: 'topLeft',
          duration: 2
        });
      }

    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with data transfer or deletion. Please try later.',
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const removeAgent = async (agent) => {
    setSelectedAgent(agent);
    try {
      // Fetch and populate agency, agent, brand, and branch data
      const agency = agentsOptions?.find((a) => a._id === agent.agency);
      const agentDetails = agency?.agentsOptions.find((a) => a._id === agent.agent);
      const brands = agent.brand?.map((brandId) => brandsData?.find((b) => b._id === brandId)) || [];
      const branch = branchData?.find((b) => b._id === agent.clientBranch);

      const populatedAgent = {
        ...agent,
        agencyName: agency?.companyName || 'N/A',
        agentName: agentDetails ? `${agentDetails?.firstName} ${agentDetails?.lastName}` : 'N/A',
        brands,
        branchName: branch?.branchName || 'N/A'
      };

      const response = await Axios.get(`${SERVER_URL}/client-agents-content/${agent.agent}`, {
        withCredentials: true,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });

      // Agent has content, show modal for data transfer
      if (response?.data && response?.data?.agentHasContent && response?.data?.clientUsers?.length) {
        setCreatorOptions(response?.data?.clientUsers);
        setAgentContent(populatedAgent); // Pass populated agent data
        setShowDeleteModal(true); // Show modal with content transfer options
      }

      // There is content but no creators to transfer to.
      else if (response?.data && response?.data?.agentHasContent && !response?.data?.clientUsers?.length) {
        notification.error({
          message: 'No Local creators found to transfer content to. Please add a creator first before deleting the agent.',
          placement: 'topLeft',
          duration: 2
        });
      }

      // No content, safe to delete agent
      else {
        await Axios.delete(`${SERVER_URL}/client-agent/${agent.agent}`, {
          withCredentials: true,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        });
        setSelectedAgent(null);
        setShowDeleteModal(false);
        getClient();
        notification.success({
          message: 'Agent removed successfully.',
          placement: 'topLeft',
          duration: 2
        });
      }
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with deletion. Please try later.',
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  // Filter selected agents based on search term
  const filteredAgents = selectedAgents.filter((agent) => {
    const agency = agentsOptions?.find((option) => option._id === agent.agency)?.companyName || '';
    const agentDetails = agentsOptions?.find((option) => option._id === agent.agency)
      ?.agentsOptions.find((a) => a._id === agent.agent) || {};
    const agentName = `${agentDetails.firstName || ''} ${agentDetails.lastName || ''}`;
    const brandNames = agent.brand?.map((brandId) => brandsData?.find((b) => b._id === brandId)?.name || '').join(', ');

    const branchName = branchData?.find((b) => b._id === agent.clientBranch)?.branchName || '';

    // Check if the search term is found in any of the fields
    return (
      agency.toLowerCase().includes(searchTerm.toLowerCase()) ||
      agentName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      brandNames.toLowerCase().includes(searchTerm.toLowerCase()) ||
      branchName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const columns = [
    {
      title: 'Agency',
      dataIndex: 'agency',
      render: (text, record) => {
        const agency = agentsOptions?.find((agent) => agent._id === record.agency);
        return agency ? `${agency.companyName}` : 'N/A';
      }
    },
    {
      title: 'Agent',
      dataIndex: 'agent',
      render: (text, record) => {
        const agency = agentsOptions?.find((agent) => agent._id === record.agency);
        const agent = agency?.agentsOptions.find((a) => a._id === record.agent);
        return agent ? `${agent.firstName} ${agent.lastName}` : 'N/A';
      }
    },
    {
      title: 'Type',
      dataIndex: 'type'
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      render: (text, record) =>
        record?.brand?.map((brandId) => {
          const brand = brandsData?.find((b) => b._id === brandId);
          return (
            <Tag key={brandId} style={{ backgroundColor: '#e483f7' }}>
              {brand ? brand.name : 'N/A'}
            </Tag>
          );
        })
    },
    {
      title: 'Branch',
      dataIndex: 'clientBranch',
      render: (text, record) => {
        const branch = branchData?.find((b) => b._id === record?.clientBranch);
        return branch ? branch.branchName : 'N/A';
      }
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Edit
            onClick={() => editAgent(record)}
            title="Edit agent"
            style={{ cursor: 'pointer', marginRight: '10px' }}
          />
          <Popconfirm
            placement="left"
            title="Are you sure you want to delete this agent?"
            onConfirm={() => removeAgent(record)}
            okText="Ok"
            cancelText="Cancel"
          >
            <Trash title="Delete agent" style={{ cursor: 'pointer' }} />
          </Popconfirm>
        </div>
      )
    }
  ];

  return (
    <Layout className="manage-layout">
      <div
        style={{
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}
      >
        <HeaderComponent text="Manage Agents" />
        <div className="users-content">
          <div className="users-container">
            <div className="users-upper-part">

              <Input
                className="inppy"
                placeholder="Search by Agency, Agent, or Brand"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                prefix={<Magnifying style={{ marginRight: '5px' }} />}
              />

              <Button
                className="butty"
                type="primary"
                onClick={addAgencySupport}
              >
                Add Agency Support
              </Button>

              <Button
                className="butty"
                type="primary"
                onClick={() => setShowSupportModal(true)}
              >
                Request Qoolo Support
              </Button>
            </div>

            <div className="table-manage">
              <Table
                size="middle"
                bordered
                dataSource={filteredAgents}
                locale={{ emptyText: <Empty description="No Agents, please add agent." /> }}
                columns={columns}
                rowKey="agent"
                pagination={{
                  defaultPageSize: 20,
                  position: 'bottom',
                  showSizeChanger: false,
                  pageSizeOptions: ['10', '20', '50', '100'],
                  hideOnSinglePage: true
                }}
              />
            </div>
          </div>
        </div>

        <Modal
          width={600}
          maskClosable={false}
          open={agentModal}
          onCancel={() => setAgentModal(false)}
          footer={null} centered
          title={<h1 style={{ textAlign: 'center' }}>{currentAgent ? 'Edit Agent' : 'Add Agency Support'}</h1>}
        >
          <div style={{ padding: '8px' }}>
            <AgentsForm
              isNew={true}
              agents={agentsOptions}
              brands={brandsData}
              branches={branchData}
              handleAddAgent={handleAddAgent}
              handleUpdateAgent={handleUpdateAgent}
              selectedAgents={selectedAgents}
              currentAgent={currentAgent}
            />
          </div>

        </Modal>

        <Modal
          width={600}
          maskClosable={false}
          open={showSupportModal}
          onCancel={() => setShowSupportModal(false)}
          footer={null}
          centered
          title={<h1 style={{ textAlign: 'center' }}>Request Qoolo Support</h1>}
        >
          <div style={{ padding: '8px' }}>
            <RequestQooloSupportForm
              brands={brandsData}
              branches={branchData}
              handleSupportRequest={handleSupportRequest} />
          </div>
        </Modal>

        {showDeleteModal && (
          <DeleteAgentModal
            agentContent={agentContent}
            creatorOptions={creatorOptions} // List of creators fetched from the backend
            onConfirm={transferDataAndDeleteAgent}
            onCancel={() => setShowDeleteModal(false)}
          />
        )}

      </div>
    </Layout>
  );
};

export default Agents;
