import React from 'react';
import { Tooltip } from 'antd';
import { ReactComponent as CreatorIcon } from '../../icons/homebase-v2/creator.svg';
import { ReactComponent as PublisherIcon } from '../../icons/homebase-v2/publisher.svg';
import { ReactComponent as InfoIcon } from '../../icons/info-icon.svg';

function HomebaseLicensesList({ data }) {
  if (!data) return null;

  const totalCreatorLicences = data.totalCreatorLicences || 0;
  const usedCreatorLicences = data.licencedCreators || 0;
  const availableCreatorLicences = totalCreatorLicences - usedCreatorLicences;

  const totalPublisherLicences = data.totalPublisherLicences || 0;
  const usedPublisherLicences = data.licencedPublishers || 0;
  const availablePublisherLicences = totalPublisherLicences - usedPublisherLicences;

  const licenseEntries = [
    { title: 'Creator: Total', value: totalCreatorLicences, icon: <CreatorIcon />, tooltip: 'The total number of Creator licenses that your Account has purchased.' },
    { title: 'Publisher: Total', value: totalPublisherLicences, icon: <PublisherIcon />, tooltip: 'The total number of Publisher licenses that your Account has purchased.' },
    { title: 'Creator: Used', value: usedCreatorLicences, icon: <CreatorIcon />, tooltip: 'The total number of Creator licenses that your Account has assigned to users.' },
    { title: 'Publisher: Used', value: usedPublisherLicences, icon: <PublisherIcon />, tooltip: 'The total number of Publisher licenses that your Account has assigned to users.' },
    { title: 'Creator: Available', value: availableCreatorLicences, icon: <CreatorIcon />, tooltip: 'The total number of Creator licenses that your Account has available.' },
    { title: 'Publisher: Available', value: availablePublisherLicences, icon: <PublisherIcon />, tooltip: 'The total number of Publisher licenses that your Account has available.' },
  ];

  return (
    <div className="homebase__simple-list">
      {licenseEntries.map((entry, index) => (
        <div key={index} className="homebase__simple-list-entry">
          <div className="homebase__list-entry-icon">{entry.icon}</div>
          <div className="homebase__simple-list-entry-wrapper">
            <span className="homebase__simple-list-entry-value">{entry.value}</span>
            <span className="homebase__simple-list-entry-label">
              <span style={{ marginRight: '0.625rem' }}>{entry.title}</span>
              <Tooltip
                color="#4962E2"
                placement="bottom"
                title={entry.tooltip}
              >
                <InfoIcon className="homebase__list-entry-info" />
              </Tooltip>
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default HomebaseLicensesList;
