/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import { Input, Button, Form, Select, Checkbox, Col, Row, Switch } from 'antd';

const { Option } = Select;

const formInit = {
  _id: null
};

const ClientUserForm = ({
  createHandler,
  updateHandler,
  isNew,
  branchs,
  data,
  brands,
  createProcess = false,
  client,
  availableCreatorLicences,
  availablePublisherLicences
}) => {
  const [form] = Form.useForm();
  if (data && data.clientBranch && data.clientBranch._id) data.clientBranch = data.clientBranch._id;

  useEffect(() => form.resetFields());
  const initialValues = { ...formInit, ...data };
  const onFinish = (values) => {
    if (isNew) createHandler(values, true);
    else updateHandler(values, false);
  };
  const onRoleChange = (value) => {
    form.setFieldsValue({ role: value });
    if (value !== 'creator') {
      form.setFieldValue('canCreateThemes', false);
    }
  };
  const onFinishFailed = (errorInfo) => console.log('Failed:', errorInfo);

  return (
    <div className="panel">
      <div className={!createProcess ? 'modal-team' : undefined}>
        <Form
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, !data)}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          key={Math.random()}
          form={form}
          colon={false}
        >
          {!createProcess && (
            <div
              className="modal-team__header"
              style={{ textAlign: 'center', marginBottom: '30px' }}
            >
              <h2>{isNew ? 'Add new' : 'Edit'} User</h2>
            </div>
          )}
          <div className="modal-team__input">
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: 'Please input your First name!' }]}
            >
              <Input placeholder="First Name" />
            </Form.Item>

            <Form.Item
              name="lastName"
              rules={[{ required: true, message: 'Please input your Last name!' }]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
            {isNew ? (
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: 'Please enter your Email!' },
                    {
                      validator: (_, value) => {
                        if (value) {
                          const reg = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g;
                          if (!reg.test(value))
                            return Promise.reject(new Error('Please enter valid email address!'));
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                  normalize={(value) => value.trim()}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              )
              : <Input placeholder="Email" value={data?.email} disabled style={{ marginBottom: '0.75rem' }} />
            }
            <Form.Item
              name="phone"
              rules={[
                { required: false, message: 'Please enter phone number!' },
                {
                  validator: (_, value) => {
                    if (value) {
                      const reg = /^[0-9+]{8,}$/g;
                      if (!reg.test(value))
                        return Promise.reject(new Error('Please enter valid phone number!'));
                    }
                    return Promise.resolve();
                  }
                }
              ]}
              normalize={(value) => value.trim()}
            >
              <Input placeholder="Phone" />

            </Form.Item>
            {isNew ? (
              <Form.Item name="role" rules={[{ required: true, message: 'Please select role!' }]}>
                <Select
                  onChange={onRoleChange}
                  placeholder="Role"
                  className="conditions-select dropdown-small"
                >
                  <Option value="creator">Creator</Option>
                  <Option value="publisher">Publisher</Option>
                </Select>
              </Form.Item>
            ) : (
              <Form.Item name="role">
                <Select disabled>
                  <Option value="creator">Creator</Option>
                  <Option value="publisher">Publisher</Option>
                </Select>
              </Form.Item>
            )}

            {/* Show this only if selected role is creator */}
            <Row justify='space-between'>
              <Col span={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.role !== currentValues.role ||
                    prevValues.canCreateThemes !== currentValues.canCreateThemes
                  }
                >
                  {({ getFieldValue, setFieldValue }) => {
                    const role = getFieldValue('role');
                    const checked = getFieldValue('canCreateThemes');
                    if (role?.includes('creator')) {
                      return (
                        <Form.Item name="canCreateThemes" valuePropName="checked">
                          <Switch
                            checked={checked}
                            onChange={(value) => setFieldValue('canCreateThemes', value)}
                          />
                          <span style={{ marginLeft: '15px' }}>Can create Themes</span>
                        </Form.Item>
                      );
                    }
                  }}
                </Form.Item>
              </Col>

              {client?.isAgency &&
                <Col span={12}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.role !== currentValues.role ||
                      prevValues.isAgent !== currentValues.isAgent
                    }
                  >
                    {({ getFieldValue, setFieldValue }) => {
                      const role = getFieldValue('role');
                      const checked = getFieldValue('isAgent');
                      if (role?.includes('creator')) {
                        return (
                          <Form.Item name="isAgent" valuePropName="checked">
                            <Switch
                              checked={checked}
                              onChange={(value) => setFieldValue('isAgent', value)}
                            />
                            <span style={{ marginLeft: '15px' }}>User is agent</span>
                          </Form.Item>
                        );
                      }
                    }}
                  </Form.Item>
                </Col>
              }
            </Row>
            <Form.Item name="brand" rules={[{ required: false }]}>
              <Select
                mode="multiple"
                allowClear
                placeholder="Brands"
                className="conditions-select dropdown-small"
                maxTagCount={1}
                filterSort={(optionA, optionB) => optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())}
              >
                {brands &&
                  brands.map((brand) => (
                    <Option key={brand._id} value={brand._id}>
                      {brand.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="jobFunction"
              rules={[{ required: true, message: 'Please select job description!' }]}
            >
              <Select placeholder="Department" className="conditions-select dropdown-small">
                <Option value="IT / Data / Tools">IT / Data / Tools</Option>
                <Option value="Executive">Executive</Option>
                <Option value="Medical Affairs">Medical Affairs</Option>
                <Option value="Sales / Commercial">Sales / Commercial</Option>
                <Option value="Market Access">Market Access</Option>
                <Option value="Communications">Communications</Option>
                <Option value="Clinical">Clinical</Option>
                <Option value="Other">Other</Option>
              </Select>
            </Form.Item>
            <Form.Item name="clientBranch" rules={[{ required: false }]}>
              <Select placeholder="Site" className="conditions-select dropdown-small">
                {branchs &&
                  branchs.map((branch) => (
                    <Option key={branch._id} value={branch._id}>
                      {branch.branchName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.role !== currentValues.role}
              style={{ display: 'flex', flexDirection: 'row', flexFlow: 'nowrap' }}
            >
              {({ getFieldValue }) => {
                const role = getFieldValue('role');
                const isFirstRoleType =
                  role === 'publisher' || (Array.isArray(role) && role[0] === 'publisher');
                const isSecondRoleType =
                  role === 'creator' || (Array.isArray(role) && role[0] === 'creator');
                const licenses = {
                  publisher: availablePublisherLicences,
                  creator: availableCreatorLicences
                };
                const licenseNames = {
                  publisher: 'Publisher',
                  creator: 'Creator'
                };

                if (isFirstRoleType || isSecondRoleType) {
                  const currentRole = isFirstRoleType ? 'publisher' : 'creator';
                  const availableLicences = licenses[currentRole];
                  const licenseName = licenseNames[currentRole];

                  if (availableLicences > 0) {
                    return (
                      <Form.Item
                        name="licenceActive"
                        valuePropName="checked"
                        label={`${licenseName} Licenses: ${availableLicences}`}
                      >
                        <Checkbox disabled={availableLicences < 1}>
                          {`Activate ${licenseName} License`}
                        </Checkbox>
                      </Form.Item>
                    );
                  }
                  return (
                    <p>{`You don't have enough ${licenseName} licences, please contact Qoolo support.`}</p>
                  );
                }
              }}
            </Form.Item>
          </div>
          <div>
            <Button
              id="company-settings_add-user"
              type="primary"
              htmlType="submit"
              style={{ margin: '15px 0px', width: !isNew ? '100%' : 'auto' }}
            >
              {isNew ? 'Add' : 'Edit'} User
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ClientUserForm;
