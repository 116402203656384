import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { notification, Layout, Button, Input, Modal, Tag, Popconfirm, Empty, Table } from 'antd';
import { ReactComponent as Magnifying } from '../../icons/magnifying-glas.svg';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { UserContext } from '../../App';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';
import { SERVER_URL } from '../../config';
import HeaderComponent from '../../components/base/HeaderComponent';
import BrandForm from '../../components/forms/BrandForm';

const Brands = () => {
  const currentuser = useContext(UserContext);
  const [brands, setBrands] = useState();
  const [brandForEdit, setBrandForEdit] = useState(null);
  const [newBrand, setNewBrand] = useState(null);

  const getBrands = async (search = null) => {
    const response =
      search && search.length > 1
        ? await Axios.get(
          `${SERVER_URL}/brands?filter=${JSON.stringify({ name: { $regex: search, $options: 'i' } })}`,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` }
          }
        )
        : await Axios.get(`${SERVER_URL}/brands`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        });
    response?.data?.items ? setBrands(response.data.items) : setBrands([]);
  };

  const getBrandForEdit = async (id) => {
    const response = await Axios.get(`${SERVER_URL}/brands/${id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` }
    });
    response?.data ? setBrandForEdit(response.data) : setBrandForEdit(null);
  };

  useEffect(() => {
    getBrands();
  }, [setBrands, setBrandForEdit, setNewBrand]);

  const deleteBrandHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/brands/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'Brand deleted successfully',
        placement: 'topLeft',
        duration: 2
      });
      getBrands();
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message || 'Problem with delete.',
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const addAndEditBrandHandler = async (formData, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/brands` : `${SERVER_URL}/brands/${brandForEdit._id}`;
    try {
      await Axios[method](route, formData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: `Brand ${isNew ? 'added' : 'updated'} successfully`,
        placement: 'topLeft',
        duration: 2
      });
      setBrandForEdit(null);
      setNewBrand(null);
      getBrands();
    } catch (error) {
      console.log(error);
      notification.error({
        message: error?.response?.data?.message || 'Problem with create/edit.',
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const handleSearch = (e) => {
    getBrands(e);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Therapeutic Areas',
      dataIndex: 'therapeuticArea',
      render: (text, record) =>
        record.therapeuticArea.map((ta) => (
          <Tag style={{ backgroundColor: '#E5f9c6' }} key={ta._id + 110}>
            {ta.name}
          </Tag>
        ))
    },
    {
      title: 'Medical Conditions',
      dataIndex: 'therapeuticAreaCondition',
      render: (text, record) =>
        record.therapeuticAreaCondition.map((cond) => (
          <Tag style={{ backgroundColor: '#C6e2de' }} key={cond._id + 120}>
            {cond.name}
          </Tag>
        ))
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px'
          }}
        >
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Edit
              onClick={() => getBrandForEdit(record._id)}
              title="Edit brand"
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              placement="left"
              title={`This will delete ${record.name}`}
              onConfirm={() => deleteBrandHandler(record._id)}
              okText="Ok"
              cancelText="Cancel"
            >
              <Trash title={`Delete ${record.name}`} />
            </Popconfirm>
          </div>
        </div>
      )
    }
  ];

  return (
    <Layout className="manage-layout">
      <div
        style={{
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}
      >
        <HeaderComponent text="Manage brands" />
        <div className="teams-content">
          <div className="teams-container">
            <div className="teams-upper-part">
              <div className="left-part">
                <Input
                  className="inppy"
                  placeholder="Search"
                  onChange={(e) => handleSearch(e.target.value)}
                  prefix={<Magnifying style={{ marginRight: '5px' }} />}
                />
                <Button className="butty" type="primary" onClick={() => setNewBrand(true)}>
                  Add new brand
                </Button>
              </div>
            </div>

            <div className="table-manage">
              <Table
                size="middle"
                bordered
                dataSource={brands}
                columns={columns}
                locale={{ emptyText: <Empty description="No Brands, please add a brand." /> }}
                rowKey="_id"
                pagination={{
                  defaultPageSize: 20,
                  position: 'bottom',
                  showSizeChanger: false,
                  pageSizeOptions: ['10', '20', '50', '100'],
                  hideOnSinglePage: false
                }}
              />
            </div>
          </div>
        </div>
        {brandForEdit && (
          <div>
            <Modal
              open={brandForEdit}
              onCancel={() => setBrandForEdit(null)}
              footer={null}
              centered
            >
              <div style={{ padding: '8px' }}>
                <div>
                  <BrandForm
                    isNew={false}
                    data={brandForEdit}
                    onSubmit={addAndEditBrandHandler}
                    SERVER_URL={SERVER_URL}
                  />
                </div>
              </div>
            </Modal>
          </div>
        )}
        {newBrand && (
          <div>
            <Modal maskClosable={false} open={newBrand} onCancel={() => setNewBrand(null)} footer={null} centered>
              <div style={{ padding: '10px 0px 40px 0px' }}>
                <div>
                  <BrandForm
                    isNew
                    data={brandForEdit}
                    onSubmit={addAndEditBrandHandler}
                    SERVER_URL={SERVER_URL}
                  />
                </div>
              </div>
            </Modal>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Brands;
