import React, { useState, useEffect } from 'react';
import readXlsxFile from 'read-excel-file';
import Fuse from 'fuse.js';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { Button, Popconfirm, Table, Modal, Divider, Empty, Tabs, Upload } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import StepNavigation from './StepNavigation';
import ContactForm from '../../components/forms/ContactForm';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';

const SettingsStepContacts = ({
  step,
  setStep,
  completedStep,
  setCompletedStep,
  SERVER_URL,
  addAndEditContactHandler,
  getContactForEdit,
  contactForEdit,
  setContactForEdit,
  deleteContactHandler,
  contacts,
  bulkAddContactsHandler,
  downloadContactsTemplateHandler
}) => {
  const [bulkUploadData, setBulkUploadData] = useState([]);
  const [therapeuticArea, setTherapeuticArea] = useState([]);

  const getTherapeuticsArea = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/therapeutic-area-for-brands`);
      setTherapeuticArea(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTherapeuticsArea();
  }, [setBulkUploadData, bulkUploadData]);

  const searchTA = (term) => {
    const options = {
      threshold: 0.5,
      keys: ['name']
    };
    const fuse = new Fuse(therapeuticArea, options);
    const found = fuse.search(term);
    return found[0]?.item;
  };

  const uploadData = (file) => {
    const newBulkUploadData = [];
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      await readXlsxFile(file, { sheet: 2 }).then(async (rows) => {
        // eslint-disable-next-line consistent-return
        const promises = rows.slice(1).map((r, index) => {
          console.log('Table data row pre condition', r);
          if (r[0] && r[1] && r[3] && r[6] && r[9] && r[10] && r[11]) {
            console.log('Table data row POST condition', r);
            return newBulkUploadData.push({
              key: index,
              firstName: r[1]?.trim(),
              middleName: r[2]?.trim(),
              lastName: r[3]?.trim(),
              employer: r[4]?.trim(),
              employerCountry: r[5]?.trim(),
              typeHCP: r[6]?.trim(),
              primarySpeciality: r[7] && r[7].length > 0 ? searchTA(r[7]?.trim()) : null,
              secondarySpeciality: r[8] && r[8].length > 0 ? searchTA(r[8]?.trim()) : null,
              countryCode: r[9]?.trim(),
              phone: r[10],
              email: r[11] && r[11].trim()
            });
          }
        });
        await Promise.all(promises);
      });
      console.log(newBulkUploadData);
      resolve(setBulkUploadData(newBulkUploadData));
    });
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName'
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName'
    },
    {
      title: 'Employer',
      dataIndex: 'employer'
    },
    {
      title: 'Type of HCP',
      dataIndex: 'typeHCP'
    },
    {
      title: 'Primary Speciality',
      dataIndex: ['primarySpeciality', 'name']
    },
    {
      title: 'Secondary Speciality',
      dataIndex: ['secondarySpeciality', 'name']
    },
    {
      title: 'Phone',
      dataIndex: 'phone'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px'
          }}
        >
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              placement="left"
              title="This will delete contact"
              onConfirm={() => deleteContactHandler(record._id)}
              okText="Ok"
              cancelText="Cancel"
            >
              <Trash title="Delete contact" />
            </Popconfirm>
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Edit
              onClick={() => getContactForEdit(record._id)}
              title="Edit contact"
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </div>
        </div>
      ),
      fixed: 'right'
    }
  ];

  return (
    <div className="company-settings-builder__step-1">
      <div className="company-settings-builder__left-side">
        <h2 className="company-settings-builder__step-subtitle">
          <span>Step 5: </span>
          <span>Add Contacts.</span>
        </h2>
        <StepNavigation step={step} setStep={setStep} completedStep={completedStep} />
        <p className="company-settings-builder__intro-text">
          Add contacts to your CRM so that you can easily send published Qoolections and capture
          additional interaction data. The table on the right will populate as you keep adding
          contacts.
        </p>
        <div className="company-settings-builder__forms">
          <Tabs defaultActiveKey="1" className="company-settings__tabs">
            <Tabs.TabPane tab="Manual" key="1">
              <ContactForm
                isNew
                onSubmit={addAndEditContactHandler}
                SERVER_URL={SERVER_URL}
                createProcess
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Bulk Upload" key="2">
              <p className="company-settings-builder__intro-text">
                You can download bulk upload template from{' '}
                <span
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={downloadContactsTemplateHandler}
                >
                  here
                  <DownloadOutlined />
                </span>
              </p>
              <div className="bulk-upload-wrapper">
                <Upload
                  className="bulk-upload-teams"
                  action={uploadData}
                  accept=".xlsx"
                  name="excelData"
                  style={{ margin: 0 }}
                >
                  <Button style={{ margin: 0 }} type="primary">
                    {bulkUploadData && bulkUploadData.length === 0
                      ? 'Upload your completed template'
                      : 'Re-Upload'}
                  </Button>
                </Upload>
              </div>
              {bulkUploadData && bulkUploadData.length > 0 && (
                <Button
                  className="bulk-upload-save-button"
                  onClick={() => bulkAddContactsHandler(bulkUploadData)}
                >
                  Add/Update Contacts
                </Button>
              )}
            </Tabs.TabPane>
          </Tabs>
        </div>
        <div className="company-settings-builder__bottom-row-space-between">
          <Button className="company-settings-builder__step-btn" onClick={() => setStep(3)}>
            Previous step
          </Button>
          <Link to="/home">
            <Button
              id="company-settings_finish"
              className="company-settings-builder__step-btn"
              type="primary"
              onClick={() => {
                if (completedStep === 4) setCompletedStep(4);
                setStep(5);
              }}
            >
              Finish
            </Button>
          </Link>
        </div>
      </div>

      <Divider type="vertical" style={{ height: 'auto' }} />

      <div className="table-manage">
        <Table
          size="middle"
          bordered
          dataSource={contacts}
          columns={columns}
          locale={{ emptyText: <Empty description="No Contacts, please add a contact." /> }}
          rowKey="_id"
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: false
          }}
        />
      </div>

      {contactForEdit && (
        <div>
          <Modal
            open={contactForEdit}
            onCancel={() => setContactForEdit(null)}
            footer={null}
            centered
          >
            <div style={{ padding: '8px' }}>
              <div>
                <ContactForm
                  isNew={false}
                  data={contactForEdit}
                  onSubmit={addAndEditContactHandler}
                  SERVER_URL={SERVER_URL}
                />
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default SettingsStepContacts;
