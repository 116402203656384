import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom/cjs/react-router-dom';

export default function HomebaseCard({ textContent, image, buttonText, buttonLink }) {
  return (
    <div className="homebase__card">
      <p className="homebase__text-content">{textContent}</p>
      <div className="homebase__card-image">{image}</div>
      <div className="homebase__button">
        <Button style={{ width: "70%" }} type="primary">
          <Link to={buttonLink}>{buttonText}</Link>
        </Button>
      </div>
    </div>
  );
}
