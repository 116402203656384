import React, { useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { notification, Layout, Button, Modal, Form, Input, Table, Empty } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import HeaderComponent from '../../components/base/HeaderComponent';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';

const BlockTypes = () => {
  const currentuser = useContext(UserContext);
  const [data, setData] = useState({ isLoading: true, data: null });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingBlockType, setEditingBlockType] = useState(null);
  const [form] = Form.useForm();

  const getBlockTypes = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/block-types`, {
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      setData({ isLoading: false, data: response.data });
    } catch (error) {
      notification.error({ message: 'Error fetching Block types', placement: 'bottomRight' });
    }
  };

  useEffect(() => {
    getBlockTypes();
  }, []);

  const showModal = (blockType = null) => {
    if (blockType) {
      form.setFieldsValue({ name: blockType.name });
      setEditingBlockType(blockType);
    } else {
      form.resetFields();
      setEditingBlockType(null);
    }
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      if (editingBlockType) {
        // Update existing Block type
        await Axios.put(`${SERVER_URL}/block-types/${editingBlockType._id}`, values, {
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        });
        notification.success({ message: 'Block type updated successfully', placement: 'bottomRight' });
      } else {
        // Add new Block type
        await Axios.post(`${SERVER_URL}/block-types`, values, {
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        });
        notification.success({ message: 'Block type added successfully', placement: 'bottomRight' });
      }
      setIsModalVisible(false);
      form.resetFields();
      getBlockTypes();
    } catch (err) {
      notification.error({ message: 'Error saving Block type', placement: 'bottomRight' });
    }
  };

  const deleteDataHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/block-types/${id}`, {
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({ message: 'Item deleted successfully', placement: 'bottomRight' });
      getBlockTypes();
    } catch (err) {
      notification.error({ message: 'Error deleting Block type', placement: 'bottomRight' });
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px'
          }}
        >
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Edit
              onClick={() => showModal(record)}
              title="Edit Block Type"
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Trash
              onClick={() => deleteDataHandler(record._id)}
              title={`Delete ${record.name}`}
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </div>
        </div>
      )
    }
  ];

  return (
    <Layout className="manage-layout">
      <div style={{ textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
        <HeaderComponent text="Block Types" />
        <div className="qoo-types-content">
          <div className="qoo-types-container">
            <div className="qoo-types-upper-part">
              <Button type="primary" onClick={() => showModal()}>
                Add new Block type
              </Button>
            </div>
            <div className="qoo-types-table" style={{ textAlign: 'center', backgroundColor: 'white' }}>
              {data.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
              {!data.isLoading && data.data && data.data.length > 0 && (
                <div className="table-manage">
                  <Table
                    size="middle"
                    bordered
                    dataSource={data.data}
                    columns={columns}
                    locale={{ emptyText: <Empty description="No Block types, please add a Block Type." /> }}
                    rowKey="_id"
                    pagination={{
                      defaultPageSize: 20,
                      position: 'bottom',
                      showSizeChanger: false,
                      pageSizeOptions: ['10', '20', '50', '100'],
                      hideOnSinglePage: false
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Modal for adding/editing Block types */}
        <Modal
          title={editingBlockType ? 'Edit Block Type' : 'Add New Block Type'}
          open={isModalVisible}
          onCancel={handleCancel}
          onOk={handleSave}
          okText={editingBlockType ? 'Update' : 'Create'}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="name"
              label="Block Type Name"
              rules={[{ required: true, message: 'Please enter the Block type name' }]}
            >
              <Input placeholder="Enter Block type name" />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Layout>
  );
};

export default BlockTypes;
