import React, { useState } from 'react';
import { Form, Select, Button, Row, Col, Switch } from 'antd';

const RequestQooloSupportForm = ({ handleSupportRequest, brands, branches }) => {
  const [form] = Form.useForm();
  const [unsureSupport, setUnsureSupport] = useState(false);

  const onFinish = (values) => {
    handleSupportRequest(values);
    form.resetFields(); // Reset form after submission
    setUnsureSupport(false); // Reset unsure support switch
  };

  const handleUnsureChange = (checked) => {
    setUnsureSupport(checked);
    form.setFieldsValue({ unsure: checked });
    if (checked) {
      // Set other fields to undefined when unsure is selected
      form.setFieldsValue({
        supportType: undefined,
        brand: undefined,
        branch: undefined
      });
    }
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Row justify='center'>
        <Col span={24}>
          <Form.Item
            name="supportType"
            rules={[{ required: !unsureSupport, message: 'Please select a type of support' }]}
          >
            <Select placeholder="Select Type of Support" disabled={unsureSupport}>
              <Select.Option value="marketing">Marketing Content Creator</Select.Option>
              <Select.Option value="medical">Medical Content Creator (MD/PhD)</Select.Option>
              <Select.Option value="crm">CRM/Analytics Expert</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row justify='center'>
        <Col span={24}>
          <Form.Item
            name="brand"
            rules={[{ required: !unsureSupport, message: 'Please select at least one brand' }]}
          >
            <Select mode="multiple" placeholder="Select Brands" disabled={unsureSupport}>
              {brands.map((brand) => (
                <Select.Option key={brand._id} value={brand._id}>
                  {brand.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row justify='center'>
        <Col span={24}>
          <Form.Item
            name="branch"
            rules={[{ required: !unsureSupport, message: 'Please select a branch' }]}
          >
            <Select placeholder="Select Branch" disabled={unsureSupport}>
              {branches.map((branch) => (
                <Select.Option key={branch._id} value={branch._id}>
                  {branch.branchName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row justify='center'>
        <Col span={24}>
          <Form.Item name="unsure" valuePropName="checked">
            <Switch
              checked={unsureSupport}
              onChange={handleUnsureChange}
              style={{ marginRight: 8 }}
            />
            We’re unsure about the type of support we need, and we would like to schedule a call to discuss.
          </Form.Item>
        </Col>
      </Row>

      <Row justify="center" style={{ marginTop: '20px' }}>
        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
          Submit
        </Button>
      </Row>
    </Form>
  );
};

export default RequestQooloSupportForm;
