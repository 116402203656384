import React, { useContext } from 'react';
import { Table, Popconfirm, Tag, Checkbox, Empty } from 'antd';
import { UserContext } from '../../App';
import { ReactComponent as Trash } from '../../icons/ph_trash.svg';
import { ReactComponent as Edit } from '../../icons/ph_note-pencil-light.svg';
import 'antd/dist/antd.min.css';

const ClientUsersTable = ({ data, deleteHandler, title, setUserId, getUserForEdit }) => {
  const user = useContext(UserContext);

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName'
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName'
    },
    {
      title: 'Department',
      dataIndex: 'jobFunction'
    },
    {
      title: 'Role',
      dataIndex: 'role',
      render: (text, record) =>
        record?.role?.map((role, index) => (
          <Tag style={{ backgroundColor: '#b3f5e1' }} key={role + index}>
            {role}
          </Tag>
        ))
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      render: (text, record) =>
        record?.brand?.map((brand) => (
          <Tag style={{ backgroundColor: '#e483f7' }} key={brand._id}>
            {brand.name}
          </Tag>
        ))
    },
    {
      title: 'Branch',
      dataIndex: ['clientBranch', 'branchName']
    },
    {
      title: 'License',
      dataIndex: 'licenceActive',
      render: (text, record) => <Checkbox disabled checked={record.licenceActive} />
    },
    {
      title: 'Action',
      render: (text, record) => (
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px'
          }}
        >
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Edit
              onClick={() => {
                getUserForEdit(record._id);
                setUserId(record._id);
              }}
              title="Edit user"
              style={{ textDecoration: 'none', color: 'black' }}
            />
          </div>
          <div style={{ margin: '2px', padding: '2px', cursor: 'pointer' }}>
            <Popconfirm
              disabled={record._id === user.data.id}
              placement="left"
              title={`This will delete ${title.toLowerCase()}`}
              onConfirm={() => {
                deleteHandler(record._id);
              }}
              okText="Ok"
              cancelText="Cancel"
            >
              <Trash title="Delete user" />
            </Popconfirm>
          </div>
        </div>
      )
    }
  ];

  return (
    <>
      <div className="table-manage">
        <Table
          size="middle"
          bordered
          dataSource={data}
          locale={{ emptyText: <Empty description="No Users, please add a user." /> }}
          columns={columns}
          rowKey="_id"
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true
          }}
        />
      </div>
    </>
  );
};

export default ClientUsersTable;
