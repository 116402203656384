import React, { useContext, useEffect, useState } from 'react';
import { Layout, Select } from 'antd';
import axios from 'axios';
import { SERVER_URL } from '../config';
import { UserContext } from '../App';
import HeaderComponent from '../components/base/HeaderComponent';
import HomebaseWidget from '../components/homebase/HomebaseWidget';
import HomebaseStatsList from '../components/homebase/HomebaseStatsList';
import HomebaseCard from '../components/homebase/HomebaseCard';
import HomebaseLicensesList from '../components/homebase/HomebaseLicensesList';
import { ReactComponent as ManagePatientsImage } from '../icons/homebase-icons/manage-patients.svg';
import { ReactComponent as ManageStudiesImage } from '../icons/homebase-icons/manage-studies.svg';
import { ReactComponent as ManageUsersImage } from '../icons/homebase-icons/manage-users.svg';
import { ReactComponent as ManageOrganizationsImage } from '../icons/homebase-icons/manage-organization.svg';


const { Content } = Layout;

const Home = () => {
  const user = useContext(UserContext);
  const isAdmin = user?.data?.role.some((r) => r === 'admin');
  const [homepageStats, setHomepageStats] = useState({});
  const [timeHorizon, setTimeHorizon] = useState('ever');
  const [dataLoading, setDataLoading] = useState(false);

  const getAdminHomePageStats = async () => {
    setDataLoading(true);
    try {
      const response = await axios.get(`${SERVER_URL}/clients-get-admin-homepage-stats?time=${timeHorizon}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.data.token}` }
      });

      if (response.status === 200) {
        setHomepageStats(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    getAdminHomePageStats();
  }, [timeHorizon]);

  return (
    <Layout className="homebase__layout">
      <HeaderComponent text={`Welcome to Qoolo, ${user.data.firstName} ${user.data.lastName}`} />
      <Content className="homebase__wrapper">
        {isAdmin && (
          <div className="homebase__content">
            <HomebaseWidget maxWidth="630px" title="What do you want to do today?" additionalClassName='cards-widget'>
              <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <div className="flex-row" style={{ marginBottom: '30px', flexGrow: 1 }}>
                  <HomebaseCard
                    textContent="Quickly add new contacts to your CRM? Or, update or manage information for existing contacts?"
                    image={<ManagePatientsImage />}
                    buttonText="Manage Contacts"
                    buttonLink="/contacts"
                  />
                  <HomebaseCard
                    textContent="Add a new brand or manage an existing one in just a few clicks. Take action now."
                    image={<ManageStudiesImage />}
                    buttonText="Manage Brands"
                    buttonLink="/brands"
                  />
                </div>
                <div className="flex-row" style={{ flexGrow: 1 }}>
                  <HomebaseCard
                    textContent="Add a new user? Or, update information for an existing user? Manage multiple users?"
                    image={<ManageUsersImage />}
                    buttonText="Manage Users"
                    buttonLink="/client-users"
                  />
                  <HomebaseCard
                    textContent="Add new information about your organization? Or, update and manage existing information?"
                    image={<ManageOrganizationsImage />}
                    buttonText="Manage Organizations"
                    buttonLink="/organizations"
                  />
                </div>
              </div>
            </HomebaseWidget>
            <section className="homebase__column">
              <HomebaseWidget
                title="Licenses"
                tooltip={
                  <div className="homebase__tooltip">
                    <p>
                      <strong>This &nbsp;</strong>
                      show you how many licenses you have purchased and how many are currently in use.
                    </p>
                  </div>
                }
              >
                <HomebaseLicensesList
                  group="licenses"
                  data={homepageStats?.client}
                  loading={false}
                  setLoading={() => { }}
                />
              </HomebaseWidget>

              <HomebaseWidget
                title="Q-Stats"
                tooltip={
                  <div className="homebase__tooltip">
                    <p>
                      These are some statistics on the use of Qoolo by users (Creators and Publishers) in your account.
                    </p>
                  </div>
                }
              >
                <Select value={timeHorizon} onChange={(value) => setTimeHorizon(value)} placeholder='Time Horizon' className='time-horizon-select'>
                  <Select.Option value='24h'>Last 24 Hours</Select.Option>
                  <Select.Option value='7d'>Last 7 Days</Select.Option>
                  <Select.Option value='30d'>Last 30 Days</Select.Option>
                  <Select.Option value='year'>This Year</Select.Option>
                  <Select.Option value='ever'>Ever</Select.Option>
                </Select>
                <HomebaseStatsList
                  group="qStatsV2"
                  data={homepageStats?.qStats}
                  loading={dataLoading}
                  isNew={true}
                />
              </HomebaseWidget>
            </section>
          </div>
        )}
      </Content>
    </Layout>
  );
};

export default Home;
