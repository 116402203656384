import React, { useEffect, useState } from 'react';
import { Select, Button, Form, Row, Col } from 'antd';

const AgentsForm = ({ agents, brands, branches, handleAddAgent, handleUpdateAgent, selectedAgents, currentAgent = null }) => {
  const [form] = Form.useForm();
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [isAgentSelected, setIsAgentSelected] = useState(false); // Track if an agent is selected

  useEffect(() => {
    form.resetFields();
    if (currentAgent) {
      // Set the values of brands and clientBranch properly when editing
      form.setFieldsValue({
        agency: currentAgent.agency,
        agent: currentAgent.agent,
        brand: currentAgent.brand,
        clientBranch: currentAgent.clientBranch, // Use _id of the clientBranch
      });
      const selectedAgency = agents.find((a) => a._id === currentAgent.agency);
      setFilteredAgents(selectedAgency?.agentsOptions || []);
      setIsAgentSelected(true); // Enable brands and branches for editing
    }
  }, [currentAgent, agents]);

  const handleAgencyChange = (agencyId) => {
    const agency = agents.find((a) => a._id === agencyId);
    if (agency) {
      // Filter out already selected agents (if not in edit mode)
      const filtered = agency.agentsOptions.filter(agent =>
        !selectedAgents.some(selected => selected.agent === agent._id || (currentAgent && currentAgent.agent === agent._id))
      );
      setFilteredAgents(filtered);
    } else {
      setFilteredAgents([]);
    }
    form.setFieldsValue({ agent: null, brand: [], clientBranch: null }); // Reset dependent fields
    setIsAgentSelected(false); // Reset agent selected state
  };

  const handleAgentChange = (agentId) => {
    if (agentId) {
      setIsAgentSelected(true); // Enable brands and branches only after an agent is selected
    } else {
      setIsAgentSelected(false);
    }
  };

  const onFinish = (values) => {
    if (currentAgent) handleUpdateAgent(values);
    else handleAddAgent(values);
    // Wait 1 seconds before resetting the form
    setTimeout(() => form.resetFields(), 1000);
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Row justify='center'>
        <Col span={24}>
          <Form.Item
            name="agency"
            rules={[{ required: true, message: 'Please select an agency' }]}
          >
            <Select
              placeholder="Select Agency"
              onChange={handleAgencyChange}
              disabled={!!currentAgent} // Disable agency selection in edit mode
            >
              {agents.map((a) => (
                <Select.Option key={a._id} value={a._id}>
                  {a.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row justify='center'>
        <Col span={24}>
          <Form.Item
            name="agent"
            rules={[{ required: true, message: 'Please select an agent' }]}
          >
            <Select
              placeholder="Select Agent"
              onChange={handleAgentChange}
              disabled={!filteredAgents.length || !!currentAgent} // Disable agent selection in edit mode
            >
              {filteredAgents.map((agent) => (
                <Select.Option key={agent._id} value={agent._id}>
                  {agent.firstName} {agent.lastName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row justify='center'>
        <Col span={24}>
          <Form.Item
            name="clientBranch"
            rules={[
              {
                required: isAgentSelected,
                message: 'Please select a branch'
              }
            ]}
          >
            <Select placeholder="Select Branch" disabled={!isAgentSelected}>
              {branches.map((branch) => (
                <Select.Option key={branch._id} value={branch._id}>
                  {branch.branchName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row justify='center'>
        <Col span={24}>
          <Form.Item
            name="brand"
            rules={[
              {
                required: isAgentSelected,
                message: 'Please select at least one brand'
              }
            ]}
          >
            <Select mode="multiple" placeholder="Select Brands" disabled={!isAgentSelected}>
              {brands.map((brand) => (
                <Select.Option key={brand._id} value={brand._id}>
                  {brand.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row justify="center" style={{ marginTop: '20px' }}>
        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
          {currentAgent ? 'Update Agent' : 'Add Agent'}
        </Button>
      </Row>
    </Form>
  );
};

export default AgentsForm;
