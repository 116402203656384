import React from 'react';
import { Tooltip } from 'antd';
import { ReactComponent as InfoIcon } from '../../icons/info-icon.svg';

function HomebaseWidget({ title, children, tooltip, maxWidth = 'auto', height = 'auto', additionalClassName }) {
  return (
    <div className={`homebase__container${additionalClassName ? ' ' + additionalClassName : ''}`} style={{ maxWidth, height }}>
      <h3 className="homebase__container-title">
        {title}
        {tooltip && (
          <Tooltip color="#4962E2" placement="bottom" title={tooltip}>
            <InfoIcon className="homebase__list-entry-info homebase__widget-info" />
          </Tooltip>
        )}
      </h3>
      {children}
    </div>
  );
}

export default HomebaseWidget;
