import React from 'react';
import { SettingOutlined, TeamOutlined, FontColorsOutlined } from '@ant-design/icons';
import Home from '../pages/Home';
import Admins from '../pages/admins/Admins';
import EditAdmin from '../pages/admins/EditAdmin';
import Users from '../pages/client-users/ClientUsers';
import Agents from '../pages/agents/Agents';
import Clients from '../pages/clients/Clients';
import EditClient from '../pages/clients/EditClient';
import Categories from '../pages/categories/Categories';
import CompanySettings from '../pages/company-settings/CompanySettingsBuilder';
import EditCategory from '../pages/categories/EditCategory';
import BlockTypes from '../pages/types/BlockTypes';
import QooTypes from '../pages/types/QooTypes';
import QoolectionTypes from '../pages/types/QoolectionTypes';
import Brands from '../pages/brands/Brands';
import Contacts from '../pages/contacts/Contacts';
import Audiences from '../pages/audiences/Audiences';
import EditAudience from '../pages/audiences/EditAudience';
import Statuses from '../pages/statuses/Statuses';
import EditStatus from '../pages/statuses/EditStatus';
import TherapeuticArea from '../pages/therapeutic-area/TherapeuticArea';
import VeevaMapping from '../pages/veeva/Mapping';
import Quiz from '../pages/quiz/Quiz';
import { ReactComponent as Manage } from '../icons/Manage.svg';
import { ReactComponent as Flower2 } from '../icons/ph_flower-lotus-light2.svg';
import { ReactComponent as UserList2 } from '../icons/ph_user-list2.svg';
import { ReactComponent as UserSquare2 } from '../icons/ph_user-square2.svg';
import { ReactComponent as HomeIcon } from '../icons/Home.svg';
import { ReactComponent as Setup } from '../icons/Setup.svg';

const routes = [
  {
    label: 'Home',
    icon: HomeIcon,
    path: '/',
    component: Home,
    showInMenu: ['superadmin', 'admin']
  },
  {
    label: 'Company Settings',
    icon: Setup,
    path: '/company-settings',
    component: CompanySettings,
    showInMenu: ['admin']
  },
  {
    label: 'Manage',
    icon: Manage,
    showInMenu: ['superadmin'],
    children: [
      {
        label: 'Show all Clients',
        path: '/clients',
        component: Clients,
        allowed: ['superadmin'],
        showInMenu: true
      },
      {
        label: 'Show all Admins',
        path: '/admins',
        component: Admins,
        allowed: ['superadmin'],
        showInMenu: true
      },
      {
        label: 'Edit Client',
        path: '/edit-client/:id',
        component: EditClient,
        allowed: ['superadmin'],
        showInMenu: false
      },
      {
        label: 'Edit Admin',
        path: '/edit-admin/:id',
        component: EditAdmin,
        allowed: ['superadmin'],
        showInMenu: false
      },
      {
        label: 'Add new Admin',
        path: '/new-admin',
        component: EditAdmin,
        allowed: ['superadmin'],
        showInMenu: false
      },
      {
        label: 'Add new Client',
        path: '/new-client',
        component: EditClient,
        allowed: ['superadmin'],
        showInMenu: false
      }
    ]
  },
  {
    label: 'Manage',
    icon: Manage,
    showInMenu: ['admin'],
    children: [
      {
        label: 'Brands',
        icon: <UserList2 />,
        path: '/brands',
        component: Brands,
        allowed: ['admin'],
        showInMenu: true
      },
      {
        label: 'Contacts',
        icon: <TeamOutlined />,
        path: '/contacts',
        component: Contacts,
        allowed: ['admin'],
        showInMenu: true
      },
      {
        label: 'Users',
        icon: <UserSquare2 />,
        path: '/client-users',
        component: Users,
        allowed: ['admin'],
        showInMenu: true
      },
      {
        label: 'Agents',
        icon: < FontColorsOutlined />,
        path: '/client-agents',
        component: Agents,
        allowed: ['admin'],
        showInMenu: true
      }
    ]
  },
  {
    label: 'Block&Qoo Settings',
    icon: Setup,
    showInMenu: ['superadmin'],
    children: [
      {
        label: 'Block Types',
        path: '/block-types',
        component: BlockTypes,
        allowed: ['superadmin'],
        showInMenu: true
      },
      {
        label: 'Qoo Types',
        path: '/qoo-types',
        component: QooTypes,
        allowed: ['superadmin'],
        showInMenu: true
      },
      {
        label: 'Qoolection Types',
        path: '/qoolection-types',
        component: QoolectionTypes,
        allowed: ['superadmin'],
        showInMenu: true
      },
    ]
  },
  {
    label: 'General Settings',
    icon: SettingOutlined,
    showInMenu: ['superadmin'],
    children: [
      {
        label: 'Show all Statuses',
        path: '/statuses',
        component: Statuses,
        allowed: ['superadmin'],
        showInMenu: true
      },
      {
        label: 'Add new Status',
        path: '/new-status',
        component: EditStatus,
        allowed: ['superadmin'],
        showInMenu: true
      },
      {
        label: 'Edit Status',
        path: '/edit-status/:statusId',
        component: EditStatus,
        allowed: ['superadmin'],
        showInMenu: false
      },
      {
        label: 'Show all Categories',
        path: '/categories',
        component: Categories,
        allowed: ['superadmin'],
        showInMenu: true
      },
      {
        label: 'Add new Category',
        path: '/new-category',
        component: EditCategory,
        allowed: ['superadmin'],
        showInMenu: true
      },
      {
        label: 'Edit Category',
        path: '/edit-category/:categoryId',
        component: EditCategory,
        allowed: ['superadmin'],
        showInMenu: false
      },
      {
        label: 'Show all Audiences',
        path: '/audiences',
        component: Audiences,
        allowed: ['superadmin'],
        showInMenu: true
      },
      {
        label: 'Add new Audience',
        path: '/new-audience',
        component: EditAudience,
        allowed: ['superadmin'],
        showInMenu: true
      },
      {
        label: 'Edit Audience',
        path: '/edit-audience/:audienceId',
        component: EditAudience,
        allowed: ['superadmin'],
        showInMenu: false
      },
      {
        label: 'Therapeutic Areas',
        path: '/therapeutic-area',
        component: TherapeuticArea,
        allowed: ['superadmin'],
        showInMenu: true
      }
    ]
  },
  {
    label: 'Veeva',
    icon: Flower2,
    showInMenu: ['admin'],
    children: [
      {
        label: 'Mapping',
        path: '/veeva-mapping',
        component: VeevaMapping,
        allowed: ['admin'],
        showInMenu: true
      }
    ]
  },
  {
    label: 'Quiz',
    icon: SettingOutlined,
    showInMenu: ['admin'],
    children: [
      {
        label: 'Quiz Results',
        path: '/quiz-results',
        component: Quiz,
        allowed: ['admin'],
        showInMenu: true
      }
    ]
  }
];
export default routes;
