import ROUTES from './routeConfig';
import HOMEBASE_STATS from './homebaseStats';
import HOMEBASE_STATS_V2 from './homebaseStats-v2';

const SERVER_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PROD_URL
    : process.env.REACT_APP_DEV_URL;

export { ROUTES, SERVER_URL, HOMEBASE_STATS, HOMEBASE_STATS_V2 };
