import React, { useContext, useRef, useState, useEffect } from 'react';
import { Dropdown, Avatar, Button } from 'antd';
import { ReactComponent as LogoutIcon } from '../../icons/logout.svg';
import { UserContext } from '../../App';

const UserActions = () => {
  const user = useContext(UserContext);
  const initials =
    (user?.data?.firstName?.slice(0, 1) || '') + (user?.data?.lastName?.slice(0, 1) || '');

  const dropdownRef = useRef();
  const triggerRef = useRef();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.removeItem('user');
    window.location.href = '/login';
  };

  const handleClickOutside = (e) => {
    if (triggerRef.current.contains(e.target)) return;

    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const menu = (
    <div className="user-actions__dropdown" ref={dropdownRef}>
      <div className="user-actions__dropdown-user">
        <Avatar
          size={60}
          className="user-actions__avatar"
          onClick={() => setDropdownVisible((previous) => !previous)}
          ref={triggerRef}
        >
          {initials}
        </Avatar>
        <div className="user-actions__dropdown-user-info">
          <span className="user-actions__dropdown-user-name">{`${user?.data?.firstName} ${user?.data?.lastName}`}</span>
          <span className="user-actions__dropdown-user-email">{user?.data?.email}</span>
          <span className="user-actions__dropdown-user-role">{user?.data?.role[0]}</span>
        </div>
      </div>
      <div className="user-actions__account-bottom">
        <Button
          onClick={handleSignOutSubmit}
          className="no-border-btn user-actions__btn"
          type="secondary"
        >
          <LogoutIcon />
          <span
            style={{ color: '#000000' }}
            className="user-actions__btn-label no-border-btn__label"
          >
            Logout
          </span>
        </Button>
      </div>
    </div>
  );

  return (
    <div className="user-actions">
      <Dropdown overlay={menu} open={dropdownVisible}>
        <Avatar
          size={48}
          className="user-actions__avatar"
          onClick={() => setDropdownVisible((previous) => !previous)}
          ref={triggerRef}
        >
          {initials}
        </Avatar>
      </Dropdown>
    </div>
  );
};

export default UserActions;
