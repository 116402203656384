/* eslint-disable no-unsafe-optional-chaining */
import React, { useContext, useEffect, useState } from 'react';
import { notification, Layout, Button, Input, Modal } from 'antd';
import Axios from 'axios';
import HeaderComponent from '../../components/base/HeaderComponent';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import Table from '../../components/tables/ClientUsersTable';
import { ReactComponent as Magnifying } from '../../icons/magnifying-glas.svg';
import ClientUserForm from '../../components/forms/ClientUserForm';

const ClientUsers = () => {
  const currentuser = useContext(UserContext);
  const [useri, setUseri] = useState([]);
  const [userData, setUserData] = useState(null);
  const [newUser, setNewUser] = useState(null);
  const [userForEdit, setUserForEdit] = useState(null);
  const [branchData, setBranchData] = useState();
  const [brandsData, setBrandsData] = useState();
  const [userId, setUserId] = useState(null);
  const [client, setClient] = useState(null);
  const [availableCreatorLicences, setAvailableCreatorLicences] = useState(0);
  const [availablePublisherLicences, setAvailablePublisherLicences] = useState(0);

  const getUserForEdit = async (id) => {
    try {
      const response = await Axios.get(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      if (response?.data) {
        setUserData(response.data);
        setUserForEdit(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelUserModal = () => {
    setNewUser(false);
    setUserForEdit(false);
  };

  const getClient = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/clients/${currentuser?.data?.client?._id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      if (response?.data?.totalCreatorLicences && response?.data?.totalPublisherLicences) {
        setAvailableCreatorLicences(
          response?.data?.totalCreatorLicences - response?.data?.licencedCreators?.length
        );
        setAvailablePublisherLicences(
          response?.data?.totalPublisherLicences - response?.data?.licencedPublishers.length
        );
      }
      setClient(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getBranches = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/client-branches`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      response?.data?.items ? setBranchData(response.data.items) : setBranchData([]);
    } catch (error) {
      console.log(error);
    }
  };

  const getBrands = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/brands`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      response?.data?.items ? setBrandsData(response.data.items) : setBrandsData([]);
    } catch (error) {
      console.log(error);
    }
  };

  const getUsers = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/client-users`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      response?.data?.items ? setUseri(response.data.items) : setUseri([]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBranches();
    getBrands();
    getUsers();
    getClient();
  }, []);

  const deleteDataHandler = async (uId) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${uId}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` }
      });
      notification.success({
        message: 'User is deleted.',
        placement: 'topLeft',
        duration: 2
      });
      getClient();
      getUsers();
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with delete. Please try later.',
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const addOrUpdateUserHandler = async (data, isNew) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/register` : `${SERVER_URL}/users/${userId}`;
    try {
      const userResponse = await Axios[method](
        route,
        { ...data, client: currentuser?.data.client },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` }
        }
      );
      const message = isNew ? (
        <div>
          <span style={{ color: '#52c41a' }}>Success</span>
          <span>, {userResponse?.data?.message.toLowerCase()}</span>
        </div>
      ) : (
        'User updated.'
      );

      notification.success({
        message,
        placement: 'topLeft',
        duration: 2
      });
      setUserForEdit(null);
      setNewUser(null);
      getClient();
      getUsers();
    } catch (error) {
      notification.error({
        message: error?.response?.data?.message || 'Problem with add/update. Please try later.',
        placement: 'topLeft',
        duration: 2
      });
    }
  };

  const handleSearch = async (search) => {
    try {
      const response =
        search && search.length > 1
          ? await Axios.get(`${SERVER_URL}/client-users?search=${search}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` }
          })
          : await Axios.get(`${SERVER_URL}/client-users`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` }
          });
      response?.data?.items ? setUseri(response.data.items) : setUseri([]);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout className="manage-layout">
      <div
        style={{
          textAlign: 'center',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch'
        }}
      >
        <HeaderComponent text="Manage Users" />
        <div className="users-content">
          <div className="users-container">
            <div className="users-upper-part">
              <Input
                className="inppy"
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
                prefix={<Magnifying style={{ marginRight: '5px' }} />}
              />
              <Button className="butty" type="primary" onClick={() => setNewUser(true)}>
                Add new user
              </Button>
            </div>

            <div className="users-table" style={{ textAlign: 'center', backgroundColor: 'white' }}>
              <Table
                data={useri}
                deleteHandler={deleteDataHandler}
                addOrUpdateUserHandler={addOrUpdateUserHandler}
                setUserId={setUserId}
                title="Users"
                brands={brandsData}
                branchs={branchData}
                availableCreatorLicences={availableCreatorLicences}
                availablePublisherLicences={availablePublisherLicences}
                getUserForEdit={getUserForEdit}
              />
            </div>
          </div>
        </div>
        <Modal
          maskClosable={false}
          open={userForEdit || newUser}
          onCancel={handleCancelUserModal}
          footer={null}
          centered
        >
          <div style={{ padding: '8px' }}>
            <div>
              {newUser && (
                <ClientUserForm
                  isNew
                  branchs={branchData}
                  brands={brandsData}
                  language={currentuser.language}
                  createHandler={addOrUpdateUserHandler}
                  SERVER_URL={SERVER_URL}
                  token={currentuser.data.token}
                  client={client}
                  availableCreatorLicences={availableCreatorLicences}
                  availablePublisherLicences={availablePublisherLicences}
                />
              )}
              {userForEdit && (
                <ClientUserForm
                  isNew={false}
                  branchs={branchData}
                  brands={brandsData}
                  data={userData}
                  language={currentuser.language}
                  updateHandler={addOrUpdateUserHandler}
                  setUserId={setUserId}
                  SERVER_URL={SERVER_URL}
                  token={currentuser.data.token}
                  client={client}
                  availableCreatorLicences={availableCreatorLicences}
                  availablePublisherLicences={availablePublisherLicences}
                />
              )}
            </div>
          </div>
        </Modal>

      </div>
    </Layout>
  );
};

export default ClientUsers;
