/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React from 'react';
import { Skeleton, Tooltip } from 'antd';
import { ReactComponent as InfoIcon } from '../../icons/info-icon.svg';
import { ReactComponent as QooIcon } from '../../icons/homebase-v2/qoo.svg';
import { ReactComponent as QoolectionIcon } from '../../icons/homebase-v2/qoolection.svg';
import { ReactComponent as ClockIcon } from '../../icons/homebase-v2/clock.svg';
import { ReactComponent as EyeIcon } from '../../icons/homebase-v2/eye.svg';

function HomebaseStatsList({ data, loading }) {

  const qStatsEntries = [
    { key: 'totalQoos', title: 'Total Qoos', type: 'number', value: data?.totalQoos, icon: <QooIcon />, tooltip: 'The total number of Qoos that were created within the selected time horizon.' },
    { key: 'totalTime', title: 'Total Time', type: 'hours', value: data?.totalTime, icon: <ClockIcon />, tooltip: 'The total time spent by viewers of Qoolections within the selected time horizon.' },
    { key: 'liveQoos', title: 'Live Qoos', type: 'number', value: data?.liveQoos, icon: <QooIcon />, tooltip: 'The total number of Qoos that are currently published or live.' },
    { key: 'averageTime', title: 'Average Time', type: 'minutes', value: data?.averageTime, icon: <ClockIcon />, tooltip: 'The total number of Qoos that are currently published or live.' },
    { key: 'totalQoolections', title: 'Total Qoolections', type: 'number', value: data?.totalQoolections, icon: <QoolectionIcon />, tooltip: 'The total number of Qoolections that were created within the selected time horizon.' },
    { key: 'totalQoolectionViews', title: 'Qoolection Views', type: 'number', value: data?.totalQoolectionViews, icon: <EyeIcon />, tooltip: 'The total number of times that all Qoolections were viewed within the selected time horizon.' },
    { key: 'liveQoolections', title: 'Live Qoolections', type: 'number', value: data?.liveQoolections, icon: <QoolectionIcon />, tooltip: 'The total number of Qoolections that are currently published or live.' },
    { key: 'uniqueViews', title: 'Unique Views', type: 'number', value: data?.uniqueViews, icon: <EyeIcon />, tooltip: 'Qoolection Views, with each unique viewer being counted for only one view.' },
  ];

  return (
    <div className='homebase__simple-list'>
      {loading ? (
        <Skeleton active />
      ) : (
        qStatsEntries.map((entry) => {
            return (
              <div key={entry.key} className="homebase__simple-list-entry">
                <div
                  className="homebase__list-entry-icon"
                  style={{
                    color: ['liveQoos', 'liveQoolections'].includes(entry.key) ? '#4962E2' : 'inherit'
                  }}
                >{entry.icon}</div>
                {/* TO-DO Add Dynamic Value */}
                <div className="homebase__simple-list-entry-wrapper">
                  <p className="homebase__simple-list-entry-value">
                    {entry.value}
                    <span>
                      {entry.type === 'percentage' && '(%)'}
                      {entry.type === 'minutes' && ' (min)'}
                      {entry.type === 'hours' && ' (hr)'}
                    </span>
                  </p>
                  {/* end TO-DO */}
                  <span className="homebase__simple-list-entry-label">{entry.title}</span>
                  {entry.tooltip && (
                    <Tooltip
                      color="#4962E2"
                      placement="bottom"
                      // eslint-disable-next-line react/no-danger
                      title={
                        <div
                          className="homebase__tooltip"
                          dangerouslySetInnerHTML={{ __html: entry.tooltip }}
                        />
                      }
                    >
                      <InfoIcon className="homebase__list-entry-info" />
                    </Tooltip>
                  )}
                </div>
              </div>
            );
        })
      )}
    </div>
  );
}

export default HomebaseStatsList;
